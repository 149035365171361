/**
 * DESCRIPTION: This is the modal body component that displays a confirmation message and two buttons to confirm or cancel the action.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */

import { GroupDetail } from "../../../../../api/src/types";

//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the modal body component

interface ConfirmationModalProps {
  closeModal: () => void;
  extraObject: {
    groups: GroupDetail[];
  };
}

export default function SequenceGroupsModalBody({ closeModal, extraObject }: ConfirmationModalProps) {
  return (
    <td>
      <table className="table w-full text-left">
        <thead>
          <tr>
            <th>Group Name</th>
            <th>Group ID</th>
            <th>Status</th>
            <th>Action</th>
            <th>Object Type</th>
            <th>Object Id</th>
          </tr>
        </thead>
        <tbody>
          {extraObject.groups.map((group, index) => (
            <tr key={index}>
              <td>{group.GroupName ?? "Unknown"}</td>
              <td>{group.GroupId ?? "Unknown"}</td>
              <td>{group.Status ?? "Unknown"}</td>
              <td>{group.Action ?? "Unknown"}</td>
              <td>{group.ObjectType ?? "Unknown"}</td>
              <td>{group.ObjectId ?? "Unknown"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </td>
  );
}
