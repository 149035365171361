/**
 * DESCRIPTION: This is the store file for the react app. This file contains the store configuration and the slices that are used to manage the app's state.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage"; // Default to localStorage for web
import { persistStore, persistReducer } from "redux-persist";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the slices

import environmentSlice from "./environment.slice";
import themeSlice from "./theme.slice";
import datePickerSlice from "./datePicker.slice";
import headerSlice from "./header.slice";
import modalSlice from "./modal.slice";
import rightDrawerSlice from "./rightDrawer.slice";
import userSlice from "./user.slice";
import historySlice from "./history.slice";
import devicesSlice from "./devices.slice";
import resourceCostRecordsSlice from "./resourceCosts.slice";
import statisticsSlice from "./statistics.slice";
import apiStatisticsSlice from "./apiStatistics.slice";
import analyticsSlice from "./analytics.slice";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Persist configuration for slices that need to be persisted

// ---- History slice
const historyPersistConfig = {
  key: "history", // Key in localStorage
  storage, // Use localStorage to persist
};
const persistedHistoryReducer = persistReducer(historyPersistConfig, historySlice);

// -- Persist configuration for datePicker
const datePickerPersistConfig = {
  key: "datePicker",
  storage,
};

// -- Apply the persistence to the datePicker slice
const persistedDatePickerReducer = persistReducer(datePickerPersistConfig, datePickerSlice);
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Combine the reducers

const rootReducer = combineReducers({
  environment: environmentSlice,
  theme: themeSlice,
  datePicker: persistedDatePickerReducer, // Persisted
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  user: userSlice,
  history: persistedHistoryReducer, // Persisted
  devices: devicesSlice,
  resourceCostRecords: resourceCostRecordsSlice,
  statistics: statisticsSlice,
  apiStatistics: apiStatisticsSlice,
  analytics: analyticsSlice,
});
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Create the store

const store = configureStore({
  reducer: rootReducer, // Use the persisted reducer as the root reducer
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable check for redux-persist
    }),
});
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Export the store and types

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store); // Export persistor for the PersistGate in index.tsx (delay rendering until the store is rehydrated)
export default store;
