/**
 * DESCRIPTION: This is the environment routes file. It contains the routes for the various environments of the application.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Icons (Ensure they are imported separately to avoid tree shaking)

import RocketLaunchIcon from "@heroicons/react/24/outline/RocketLaunchIcon";
import AdjustmentsHorizontalIcon from "@heroicons/react/24/outline/AdjustmentsHorizontalIcon";
import CodeBracketIcon from "@heroicons/react/24/outline/CodeBracketIcon";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// -- Define the icon classes

const iconClasses = `h-4 w-4`;
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// -- Define the routes

type EnvironmentRoute = {
  icon: JSX.Element;
  name: string;
  url?: string;
};

const routes: EnvironmentRoute[] = [
  {
    icon: <RocketLaunchIcon className={iconClasses} />,
    name: "Production",
    url: "https://app.prod.evolve-mojo-provisioning.staff.service.justice.gov.uk",
  },
  {
    icon: <AdjustmentsHorizontalIcon className={iconClasses} />,
    name: "Testing",
    url: "https://app.test.evolve-mojo-provisioning.staff.service.justice.gov.uk",
  },
  {
    icon: <CodeBracketIcon className={iconClasses} />,
    name: "Development",
    url: "https://app.dev.evolve-mojo-provisioning.staff.service.justice.gov.uk",
  },
];

export default routes;
