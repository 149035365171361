/**
 * DESCRIPTION: This is a slice that contains the user state and actions to fetch the current user ID, user data, and user photo.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import api from "../services/api.service";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "@microsoft/microsoft-graph-types";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Redux Objects

import { RootState } from ".";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import types

import { UserPrincipal } from "../types/userPrincipal";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define types and interfaces

interface UserState {
  data: User | null;
  photo: string | null;
  principal: UserPrincipal | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the initial state

const initialState: UserState = {
  data: null,
  photo: null,
  principal: null,
  status: "idle",
  error: null,
};
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the async thunks

// -- Async thunk to fetch the current user ID from the Static Web App authentication
export const fetchUserPrincipal = createAsyncThunk("user/fetchCurrentUserId", async () => {
  const response = await api.getAuthMe();
  return response?.data?.clientPrincipal || null;
});

// -- Async thunk to fetch user data from the api using the current user ID
export const fetchUserData = createAsyncThunk("user/fetchUserData", async (_, { getState }) => {
  const state = getState() as RootState;
  const userId = state.user.principal?.userId;

  if (!userId) {
    throw new Error("No current user ID found");
  }

  const response = await api.getUser(userId);
  return response.data as User; // Assuming response data is of type User
});

// -- Async thunk to fetch the user photo from the api using the current user ID
export const fetchUserPhoto = createAsyncThunk("user/fetchUserPhoto", async (_, { getState }) => {
  const state = getState() as RootState;
  const userId = state.user.principal?.userId;

  if (!userId) {
    throw new Error("No current user ID found");
  }

  try {
    const response = await api.getUserPhoto(userId);
    return URL.createObjectURL(response.data); // Create URL from the photo blob
  } catch (error) {
    throw new Error("Failed to fetch user photo");
  }
});
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the slice

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // -- Handle fetching the current user ID
      .addCase(fetchUserPrincipal.fulfilled, (state, action) => {
        state.principal = action.payload;
      })
      // -- Handle fetching user data
      .addCase(fetchUserData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch user data";
      })
      // -- Handle fetching user photo
      .addCase(fetchUserPhoto.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserPhoto.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.photo = action.payload;
      })
      .addCase(fetchUserPhoto.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch user photo";
      });
  },
});
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Export the reducer and actions

export default userSlice.reducer;
