/**
 * DESCRIPTION: This is the PageContent component that is used to display the main content of the application.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import { Suspense, lazy, useEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { APPLICATION_ROLES } from "../utils/globalConstantUtil";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Redux Objects

import { RootState } from "../redux";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Components

import routes from "../routes";
import Header from "./Header";
import SuspenseContent from "./SuspenseContent";
import Unauthorised from "../pages/401";
const Page404 = lazy(() => import("../pages/404"));
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the component

function PageContent() {
  const mainContentRef = useRef(null);
  const rightDrawer = useSelector((state: RootState) => state.rightDrawer);
  const { pageTitle } = useSelector((state: any) => state.header);
  const { principal, status } = useSelector((state: RootState) => state.user);

  const [loading, setLoading] = useState(true);

  // -- Scroll to top of the page on page change
  useEffect(() => {
    mainContentRef.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [pageTitle]);

  // -- Show loading spinner if user authentication is in progress
  useEffect(() => {
    if (status === "succeeded" || status === "failed") {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [status]);

  return (
    <div className={`drawer-content flex flex-col flex-1 transition-all duration-500 ${rightDrawer.isOpen ? "mr-80 md:mr-96" : ""}`}>
      <Header />
      <main className="flex-1 overflow-y-auto md:pt-6 pt-6 px-6 bg-base-200 pb-6" ref={mainContentRef}>
        {/* Show loading spinner if user authentication is in progress */}
        {loading ? (
          <SuspenseContent />
        ) : (
          <Suspense fallback={<SuspenseContent />}>
            <Routes>
              {routes.map((route, key) => {
                // -- Check if user has access to the component if allowedRoles is defined
                let hasAccess = route.allowedRoles ? principal?.userRoles?.some((role: string) => route.allowedRoles.includes(role)) : true;

                // If user is an administrator of the portal, allow access to all routes
                if (principal?.userRoles?.includes(APPLICATION_ROLES.Administrator)) {
                  hasAccess = true;
                }

                if (hasAccess) {
                  return <Route key={key} path={route.path} element={<route.component />} />;
                } else {
                  return <Route key={key} path={route.path} element={<Unauthorised />} />;
                }
              })}

              {/* Redirect unknown URL to 404 page */}
              <Route path="*" element={<Page404 />} />
            </Routes>
          </Suspense>
        )}
      </main>
    </div>
  );
}

export default PageContent;
