/**
 * DESCRIPTION: This is a suspense content component that is used to display a loading spinner when the application is loading.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the component

function SuspenseContent() {
  return (
    <div className="flex justify-center items-center h-full w-full !overflow-hidden">
      <div className="loading loading-dots w-20" />
    </div>
  );
}

export default SuspenseContent;
