/**
 * DESCRIPTION: This is the layout component that contains the main layout of the application. It contains the page content, left sidebar, right sidebar, notifications and modals.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import { useSelector } from "react-redux";
import { RootState } from "../redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Components

import ModalLayout from "./ModalLayout";
import PageContent from "./PageContent";
import LeftSidebar from "./LeftSidebar";
import RightSidebar from "./RightSidebar";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the component

function Layout() {
  const theme = useSelector((state: RootState) => state.theme.state);

  return (
    <>
      {/* Left drawer - containing page content and side bar (always open) */}
      <div className="drawer lg:drawer-open">
        <input id="left-sidebar-drawer" type="checkbox" className="drawer-toggle" />
        <PageContent />
        <LeftSidebar />
      </div>

      {/* Right drawer - containing secondary content like notifications list etc.. */}
      <RightSidebar />

      {/** Toast Notification layout container */}
      <ToastContainer theme={theme} />

      {/* Modal layout container */}
      <ModalLayout />
    </>
  );
}

export default Layout;
