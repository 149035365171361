/**
 * DESCRIPTION: This is the right sidebar component that displays the right sidebar of the application. The sidebar contains notifications and other secondary content.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import { useDispatch, useSelector } from "react-redux";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Redux Objects

import { closeRightDrawer } from "../redux/rightDrawer.slice";
import { RIGHT_DRAWER_TYPES } from "../utils/globalConstantUtil";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Icons

import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import HistoryBodyRightDrawer from "./components/HistoryBodyRightDrawer";
import NotificationBodyRightDrawer from "./components/NotificationBodyRightDrawer";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the component

function RightSidebar() {
  const { isOpen, bodyType, extraObject, header, secondaryHeaderButton } = useSelector((state: any) => state.rightDrawer);
  const dispatch = useDispatch();

  // -- Function to close the drawer
  const close = () => {
    dispatch(closeRightDrawer());
  };

  return (
    <div className={`fixed z-20 inset-y-0 right-0 transform ease-in-out ${isOpen ? "translate-x-0" : "translate-x-full"} transition-all duration-500 w-80 md:w-96`}>
      <section className="w-full h-full bg-base-100 shadow-xl">
        <div className="relative pb-5 flex flex-col h-full">
          {/* Header */}
          <div className="navbar flex pl-4 pr-4 shadow-md items-center">
            {/* Close Button */}
            <button className="btn btn-circle btn-sm" onClick={close}>
              <XMarkIcon className="h-5 w-5" />
            </button>
            <span className="ml-4 font-bold text-xl">{header}</span>

            {/* Spacer to push secondaryHeaderButton to the right */}
            <div className="ml-auto">{secondaryHeaderButton && <div>{secondaryHeaderButton}</div>}</div>
          </div>

          {/* Drawer Body */}
          <div className="overflow-y-scroll overflow-x-visible pl-4 pr-4 pt-4 pb-6">
            <div className="flex flex-col w-full">
              {bodyType === RIGHT_DRAWER_TYPES.HISTORY ? (
                <HistoryBodyRightDrawer {...extraObject} closeRightDrawer={close} />
              ) : bodyType === RIGHT_DRAWER_TYPES.NOTIFICATION ? (
                <NotificationBodyRightDrawer {...extraObject} closeRightDrawer={close} />
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RightSidebar;
