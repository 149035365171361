/**
 * DESCRIPTION: This is a slice that contains the resource cost records state and actions to fetch and update the records.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import api from "../services/api.service";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define types and interfaces

export interface ResourceCostRecord {
  resourceId: string;
  resourceType: string;
  resourceName: string;
  cost: number;
  azurePortalUrl: string;
}

interface ResourceCostRecordsState {
  records: ResourceCostRecord[]; // Array to store the records
  status: "idle" | "loading" | "succeeded" | "failed"; // Track the status of the API call
  error: string | null;
}
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the initial state

// Define the initial state
const initialState: ResourceCostRecordsState = {
  records: [],
  status: "idle",
  error: null,
};
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the async thunk to fetch the records

export const fetchResourceCostRecords = createAsyncThunk("resourceCostRecords/fetchResourceCostRecords", async ({ startDate, endDate }: { startDate: string; endDate: string }) => {
  try {
    const response = await api.admin.getResourceCosts({
      startDate,
      endDate,
    });
    return response?.data;
  } catch (error) {
    throw new Error("Failed to fetch resource cost records");
  }
});
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the slice

const resourceCostRecordsSlice = createSlice({
  name: "resourceCostRecords",
  initialState,
  reducers: {
    // -- Reducer to manually update the records if needed
    setResourceCostRecords(state, action) {
      state.records = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResourceCostRecords.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchResourceCostRecords.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.records = action.payload;
      })
      .addCase(fetchResourceCostRecords.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch recource cost records";
      });
  },
});
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Export the reducer and actions

export const { setResourceCostRecords } = resourceCostRecordsSlice.actions;
export default resourceCostRecordsSlice.reducer;
