/**
 * DESCRIPTION: This is a slice that contains the header state and actions to update the page title and show notifications.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import { createSlice } from "@reduxjs/toolkit";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the slice

export const headerSlice = createSlice({
  name: "header",
  initialState: {
    pageTitle: "Home",
    noOfNotifications: 1,
  },
  reducers: {
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload.title;
    },
  },
});

export const { setPageTitle } = headerSlice.actions;

export default headerSlice.reducer;
