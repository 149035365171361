/**
 * DESCRIPTION: This is the modal body component that is used to edit a user's role in the application.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import { useState, useEffect } from "react";
import api from "../../../../services/api.service";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define types and interfaces

import { User } from "../../../../types/user";

interface AppRole {
  id: string;
  name: string;
  assignmentGroupId: string;
}

interface EditUserModalBodyProps {
  closeModal: () => void;
  extraObject: {
    user: User;
    refreshAction: () => void;
  };
}
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the component

export default function EditUserModalBody({ closeModal, extraObject }: EditUserModalBodyProps) {
  const user = extraObject.user;
  const [appRoles, setAppRoles] = useState<AppRole[]>([]);
  const [selectedRole, setSelectedRole] = useState<AppRole | null>(null); // Selected role
  const [isSaving, setIsSaving] = useState(false);

  // -- Fetch app roles when modal is opened
  useEffect(() => {
    api.admin
      .getAppRoles()
      .then((response) => {
        setAppRoles(response.data);

        // -- Set initial selected role based on current role
        const currentRoles = response.data.find((role: AppRole) => role.id === user.roles[0]);
        setSelectedRole(currentRoles || null);
      })
      .catch((error) => {
        console.error("Error fetching app roles:", error);
      });
  }, [user]);

  // -- Handle the save user action
  const handleSave = async () => {
    if (selectedRole) {
      setIsSaving(true);

      try {
        // -- Add user to the new role's group
        await api.admin.portalUsers.post({ userId: user.id, roles: [selectedRole.name] });
        closeModal();
        extraObject.refreshAction();
      } catch (error) {
        console.error("Error updating user roles:", error);
        alert("Failed to update user roles.");
      } finally {
        setIsSaving(false);
      }
    } else {
      alert("Please select a role.");
    }
  };

  return (
    <>
      {isSaving ? (
        <div className="flex flex-col items-center justify-center mt-4">
          <div className="loading loading-dots loading-md inline-block h-8 border-4 rounded-full"></div>
          <span className="mt-2">Updating User...</span>
        </div>
      ) : (
        <>
          {/* User Info */}
          <div className="flex items-center space-x-3 mt-4">
            <img src={user.avatar ? user.avatar : "/user"} alt={user.name} className="w-16 h-16 rounded-full" />
            <div>
              <div className="font-bold">{user.name}</div>
              <div className="text-sm text-gray-500">{user.email}</div>
            </div>
          </div>

          {/* Role Dropdown */}
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Select Role</label>
            <select
              className="select select-bordered w-full"
              value={selectedRole ? selectedRole.id : ""}
              onChange={(e) => {
                const selectedId = e.target.value;
                const selectedAppRole = appRoles.find((role) => role.id === selectedId);
                setSelectedRole(selectedAppRole || null);
              }}
            >
              <option value="">-- Select a Role --</option>
              {appRoles.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name} {/* Display the role name */}
                </option>
              ))}
            </select>
          </div>

          {/* Action Buttons */}
          <div className="modal-action mt-4">
            <button className="btn btn-ghost" onClick={closeModal}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={handleSave}>
              Update User
            </button>
          </div>
        </>
      )}
    </>
  );
}
