/**
 * DESCRIPTION: This is the modal body component that displays a JSON object in a collapsible tree format.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import ReactJson, { ReactJsonViewProps } from "@microlink/react-json-view";
import { useSelector } from "react-redux";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Redux Objects

import { RootState } from "../../redux";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define types and interfaces

interface JsonViewerModalBodyProps {
  closeModal: () => void;
  extraObject: { payload: object };
}
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the component

export default function JsonViewerModal({ closeModal, extraObject }: JsonViewerModalBodyProps) {
  const isDarkTheme = useSelector((state: RootState) => state.theme.state === "dark");
  const theme = isDarkTheme ? "summerfruit" : "summerfruit:inverted";

  const options: ReactJsonViewProps = {
    src: extraObject.payload,
    name: false,
    theme: theme,
    iconStyle: "square",
    indentWidth: 4,
    displayDataTypes: false,
    style: {
      padding: 25,
      borderRadius: 15,
    },
  };

  return (
    <>
      {extraObject.payload ? (
        <div className="max-h-96">
          <ReactJson {...options} />
        </div>
      ) : (
        <div className="text-center p-4">No data to display</div>
      )}
    </>
  );
}
