/**
 * DESCRIPTION: This is the login page for the react app. This page is displayed when the user is not authenticated and provides a button to trigger the MSAL login process.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the login page

function Login() {
  // -- Define the handleLogin function
  const handleLogin = () => {
    window.location.href = "/.auth/login/aad";
  };

  return (
    <div className="min-h-screen bg-base-200 flex items-center justify-center">
      <div className="card mx-auto w-full max-w-xl shadow-xl rounded-lg">
        <div className="bg-base-100 rounded-xl p-14 text-center">
          {/* Logo and Portal Information */}
          <div className="mb-6">
            <img src="/logoWhite192.png" className="w-16 inline-block mb-4" alt="logo" />
            <h1 className="text-3xl font-bold mb-2">Evolve Converge and Accelerate</h1>
            <h2 className="text-xl font-bold mt-4">Device Provisioning Portal</h2>
          </div>

          {/* Image */}
          <div className="my-10">
            <img src="./intro.gif" alt="Evolve Provisioning Portal" className="w-96 mx-auto rounded-lg shadow-lg" />
          </div>

          {/* Login Button */}
          <div className="mt-8">
            <button onClick={handleLogin} className="btn btn-primary w-48">
              Sign in with Microsoft
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
