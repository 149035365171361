/**
 * DESCRIPTION: This is a slice that contains the right drawer state and actions to open and close the right drawer.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import { createSlice } from "@reduxjs/toolkit";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the slice

export const rightDrawerSlice = createSlice({
  name: "rightDrawer",
  initialState: {
    header: "", // current  title state management
    secondaryHeaderButton: null, // secondary header button state management
    isOpen: false, // right drawer state management for opening closing
    bodyType: "", // right drawer content management
    extraObject: {},
  },
  reducers: {
    openRightDrawer: (state, action) => {
      const { header, secondaryHeaderButton, bodyType, extraObject } = action.payload;
      state.isOpen = true;
      state.bodyType = bodyType;
      state.header = header;
      state.secondaryHeaderButton = secondaryHeaderButton;
      state.extraObject = extraObject;
    },

    closeRightDrawer: (state) => {
      state.isOpen = false;
      state.bodyType = "";
      state.header = "";
      state.secondaryHeaderButton = null;
      state.extraObject = {};
    },
  },
});
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Export the slice actions

export const { openRightDrawer, closeRightDrawer } = rightDrawerSlice.actions;
export default rightDrawerSlice.reducer;
