/**
 * DESCRIPTION: This is the modal body component that is used to add a new user to the application.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import { useState, useEffect } from "react";
import debounce from "debounce";
import api from "../../../../services/api.service";
import { searchUsersWithPhotos } from "../../../../utils/searchUsersWithPhoto";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define types and interfaces

interface UserSearchResult {
  id: string;
  displayName: string;
  userPrincipalName: string;
  photo: string;
}

interface AppRole {
  id: string;
  name: string;
  assignmentGroupId: string;
}

interface AddNewUserModalProps {
  closeModal: () => void;
  extraObject: {
    refreshAction: () => void;
  };
}
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the component

export default function AddNewUserModal({ closeModal, extraObject }: AddNewUserModalProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<UserSearchResult[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserSearchResult | null>(null);
  const [appRoles, setAppRoles] = useState<AppRole[]>([]);
  const [selectedRole, setSelectedRole] = useState<AppRole | null>(null);
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Manage dropdown state

  // -- Fetch the application roles
  useEffect(() => {
    api.admin
      .getAppRoles()
      .then((response) => {
        setAppRoles(response.data);
      })
      .catch((error) => {
        console.error("Error fetching app roles:", error);
      });
  }, []);

  // -- Debounce search users function
  const searchUsers = debounce((query: string) => {
    if (query.length >= 3) {
      setLoading(true);
      searchUsersWithPhotos(query)
        .then((usersWithPhotos) => {
          setSearchResults(usersWithPhotos);
        })
        .catch((error) => {
          console.error("Error searching users:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setSearchResults([]); // Clear results if the query is too short
    }
  }, 300);

  // -- Execute search when search query changes
  useEffect(() => {
    setSelectedUser(null);
    searchUsers(searchQuery);
  }, [searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  // -- Handle save button click
  const handleSave = async () => {
    if (selectedUser && selectedRole) {
      setIsSaving(true);
      try {
        await api.admin.portalUsers.post({ userId: selectedUser.id, roles: [selectedRole.name] });
        closeModal();
        extraObject.refreshAction();
      } catch (error) {
        console.error("Error adding user to group:", error);
        alert("Failed to add user to the group.");
      } finally {
        setIsSaving(false);
      }
    } else {
      alert("Please select a user and a role.");
    }
  };

  // -- Handle user select
  const handleUserSelect = (user: UserSearchResult) => {
    setSelectedUser(user);
    setIsDropdownOpen(false); // Close the dropdown on user select
  };

  return (
    <>
      {isSaving ? (
        <div className="flex flex-col items-center justify-center mt-4">
          <div className="loading loading-dots loading-md inline-block h-8 border-4 rounded-full"></div>
          <span className="mt-2">Adding User...</span>
        </div>
      ) : (
        <>
          {/* Search User */}
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Search User by UPN</label>
            <div className="relative mt-1">
              <input type="text" className="input input-bordered w-full" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder="Search for a user..." />
            </div>
          </div>

          {/* Search User Results */}
          <div className="mt-4">
            {loading ? (
              <div className="flex justify-center items-center space-x-2">
                <div className="loading loading-dots loading-md inline-block mt-2 h-8 border-4 rounded-full"></div>
                <span>Searching users</span>
              </div>
            ) : searchResults.length > 0 ? (
              <div className="dropdown dropdown-bottom block">
                <label
                  tabIndex={0}
                  className="btn btn-outline w-full"
                  onClick={() => setIsDropdownOpen((prev) => !prev)} // Toggle dropdown open state
                >
                  {selectedUser ? (
                    <>
                      <img src={selectedUser.photo ? selectedUser.photo : "/user.png"} alt="Selected User" className="w-6 h-6 rounded-full inline-block mr-2" />
                      {selectedUser.displayName} ({selectedUser.userPrincipalName})
                    </>
                  ) : (
                    "Select a User"
                  )}
                </label>
                {isDropdownOpen && ( // Render dropdown only if open
                  <ul tabIndex={0} className="dropdown-content menu flex flex-row p-2 shadow bg-base-100 rounded-box w-full max-h-60 overflow-auto">
                    {searchResults.map((user) => (
                      <li key={user.id} onClick={() => handleUserSelect(user)}>
                        <span className="flex items-center space-x-3">
                          <img src={user.photo ? user.photo : "/user.png"} alt={user.displayName} className="w-8 h-8 rounded-full" />
                          <span>{user.displayName}</span>
                          <span className="text-xs text-gray-500">{user.userPrincipalName}</span>
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ) : (
              searchQuery.length >= 3 && <p className="text-md text-gray-500 text-center">No users found</p>
            )}
          </div>

          {/* Select Role */}
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Select Role</label>
            <select
              className="select select-bordered w-full"
              value={selectedRole ? selectedRole.id : ""}
              onChange={(e) => {
                const selectedId = e.target.value;
                const selectedAppRole = appRoles.find((role) => role.id === selectedId);
                setSelectedRole(selectedAppRole || null);
              }}
            >
              <option value="">-- Select a Role --</option>
              {appRoles.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </select>
          </div>

          {/* Action Buttons */}
          <div className="modal-action mt-4">
            <button className="btn btn-ghost" onClick={closeModal}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={handleSave}>
              Add User
            </button>
          </div>
        </>
      )}
    </>
  );
}
