/**
 * DESCRIPTION: This is the NotificationBodyRightDrawer component that is used to display the notifications in the right drawer.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the component

function NotificationBodyRightDrawer() {
  return (
    <>
      <div className={"grid mt-3 card bg-base-200 rounded-box p-3"}>Example Notification</div>
    </>
  );
}

export default NotificationBodyRightDrawer;
