/**
 * DESCRIPTION: This is a sidebar submenu component that is used to display submenus in the sidebar.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Icons

import ChevronDownIcon from "@heroicons/react/24/outline/ChevronDownIcon";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the component

interface SidebarSubmenuProps {
  submenu: { path: string; name: string; icon?: JSX.Element }[]; // Adjust to match the submenu structure
  name: string;
  icon: JSX.Element;
}

export default function SidebarSubmenu({ submenu, name, icon }: SidebarSubmenuProps) {
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);

  // -- Automatically expand the submenu if the current page is in the submenu
  useEffect(() => {
    const isCurrentPathInSubmenu = submenu.some((m) => location.pathname.includes(m.path));
    if (isCurrentPathInSubmenu) {
      setIsExpanded(true); // Expand the submenu if the current route matches any submenu item
    } else {
      setIsExpanded(false); // Collapse otherwise (optional)
    }
  }, [location.pathname, submenu]);

  return (
    <div className="flex flex-col">
      {/** Route header */}
      <div className="w-full block cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
        <span className="mr-1">{icon}</span> {name}
        <ChevronDownIcon className={`w-5 h-5 mt-1 float-right delay-400 duration-500 transition-all ${isExpanded ? "rotate-180" : ""}`} />
      </div>

      {/** Submenu list */}
      <div className={`w-full ${isExpanded ? "" : "hidden"}`}>
        <ul className="menu menu-compact">
          {submenu.map((m, k) => {
            return (
              <li key={k}>
                <Link to={`/portal/${m.path}`}>
                  {m.icon} {m.name}
                  {location.pathname.endsWith(m.path) ? <span className="absolute mt-1 mb-1 inset-y-0 left-0 w-1 rounded-tr-md rounded-br-md bg-primary" aria-hidden="true"></span> : null}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
