/**
 * DESCRIPTION: This is a component that displays the left sidebar of the application. The sidebar contains links to the various pages of the application.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import { useSelector } from "react-redux";
import { Suspense, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { APPLICATION_ROLES } from "../utils/globalConstantUtil";
import packageInfo from "../../package.json";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Redux Objects

import { RootState } from "../redux";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Icons

import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import ChevronUpDownIcon from "@heroicons/react/24/outline/ChevronUpDownIcon";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Components

import EnvironmentRoutes from "../routes/environment.routes";
import SidebarRoutes from "../routes/sidebar";
import SidebarSubmenu from "./SidebarSubmenu";
import SuspenseContent from "./SuspenseContent";
import { showToastNotification } from "../utils/toastNotification";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the component

function LeftSidebar() {
  const location = useLocation();
  const isDarkTheme = useSelector((state: RootState) => state.theme.state === "dark");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // -- Get the active environment from Redux
  const activeEnvironment = useSelector((state: RootState) => state.environment.state);

  // -- Function to handle the environment selection
  const handleEnvironmentSelect = (route) => {
    if (!route.url || route.url === "") {
      showToastNotification(`Could not find the URL for environment: ${route.name}.`, "error");
    }
    // Redirect if the selected environment is different from the current one
    else if (route.url !== activeEnvironment?.url) {
      window.location.href = route.url; // Redirect to the selected environment site
    } else {
      showToastNotification(`You are already in the ${route.name} environment.`, "info");
      setIsDropdownOpen(false); // Close the dropdown
    }
  };

  // -- Get the user principal from Redux
  const { principal } = useSelector((state: RootState) => state.user);

  // -- Function to close the drawer
  const close = () => {
    const drawer = document.getElementById("left-sidebar-drawer") as HTMLInputElement;
    if (drawer) {
      drawer.checked = false;
    }
  };

  // -- Function to toggle the dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <div className="drawer-side z-40">
        <label htmlFor="left-sidebar-drawer" className="drawer-overlay"></label>
        <ul className="menu pt-2 w-72 bg-base-100 min-h-full text-base-content">
          <div tabIndex={0} className="dropdown relative cursor-pointer">
            <li className="mb-2 font-semibold text-xl" onClick={toggleDropdown}>
              <div className="flex items-center w-full">
                <img className="mask mask-squircle w-6 mr-2" src={isDarkTheme ? "/logoWhite192.png" : "/logoBlack192.png"} alt="Logo" />
                <div className="inline-flex items-center w-full">
                  <div>
                    <div className="text-sm">Evolve Provisioning Portal</div>
                    {/* Render the active environment name */}
                    {activeEnvironment && (
                      <span className="flex text-xs font-normal">
                        <span className="mr-1">{activeEnvironment.icon}</span>
                        {activeEnvironment?.name}
                        {/* Show the active environment version from package.json */}
                        {activeEnvironment?.name && ` (v${packageInfo.version})`}
                      </span>
                    )}
                  </div>
                </div>
                <ChevronUpDownIcon className="ml-auto w-6 h-6" />
              </div>
            </li>

            {/* Conditionally render the dropdown content based on state */}
            {isDropdownOpen && (
              <ul tabIndex={0} className="menu bg-base-200 dropdown-content rounded-md z-50 w-full shadow-lg">
                {EnvironmentRoutes.map((route, index) => {
                  return (
                    <li key={index} onClick={() => handleEnvironmentSelect(route)}>
                      <span className={route?.name === activeEnvironment?.name ? "active" : ""}>
                        <span className="mr-1">{route.icon}</span>
                        {route?.name}
                        {/* Show the active environment version from process.env */}
                        {route?.name === activeEnvironment?.name && ` (v${packageInfo.version})`}
                      </span>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>

          {/* Show loading spinner if the routes are being loaded */}
          <Suspense fallback={<SuspenseContent />}>
            {SidebarRoutes.map((route, k) => {
              let show = false;
              // Function to check if a user has access to a given pageRoute
              const hasAccess = (route) => {
                return route?.allowedRoles ? principal?.userRoles?.some((role: string) => route.allowedRoles.includes(role)) : true;
              };

              // Check if the user has access to the current route
              if (route) {
                show = hasAccess(route);
              }

              // If the route has a submenu, filter the submenu items based on access
              let accessibleSubmenu = [];
              if (route.submenu) {
                accessibleSubmenu = route.submenu.filter((submenuItem) => {
                  const submenuPageRoute = SidebarRoutes.find((page) => page.path === submenuItem.path);
                  return submenuPageRoute && hasAccess(submenuPageRoute);
                });

                // If any submenu item is accessible, allow showing the submenu
                show = show || accessibleSubmenu.length > 0;
              }

              // If user is a global administrator of the portal, allow access to all routes and submenu items
              if (principal?.userRoles?.includes(APPLICATION_ROLES.Administrator)) {
                show = true;
                accessibleSubmenu = route.submenu; // Show all submenu items if admin
              }

              // Render the route or submenu only if access is granted
              if (show) {
                return (
                  <li className="" key={k}>
                    {route.submenu ? (
                      // Render the filtered submenu items
                      <SidebarSubmenu submenu={accessibleSubmenu} name={route?.name} icon={route?.icon} />
                    ) : (
                      // Render a single route
                      <NavLink end to={"/portal/" + route.path} className={({ isActive }) => `${isActive ? "font-semibold bg-base-200" : "font-normal"}`}>
                        {route.icon} {route?.name}
                        {location.pathname.endsWith(route.path) ? <span className="absolute inset-y-0 left-0 w-1 rounded-tr-md rounded-br-md bg-primary" aria-hidden="true"></span> : null}
                      </NavLink>
                    )}
                  </li>
                );
              } else {
                return null;
              }
            })}
          </Suspense>
        </ul>
      </div>
    </>
  );
}

export default LeftSidebar;
