/**
 * DESCRIPTION: This is a slice that is used to store the history of selected objects. This is used to allow the user to navigate back to previously selected objects.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Laptop from "../assets/images/laptop.png";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the slice

export interface HistoryRecord {
  id: string;
  serial: string;
  url: string;
  upn: string;
  image?: string;
  timestamp?: string;
}

// Create the slice
const historySlice = createSlice({
  name: "history",
  initialState: {
    records: [] as HistoryRecord[],
  },
  reducers: {
    // Add an record to the history
    addRecordToHistory: (state, action: PayloadAction<HistoryRecord>) => {
      // Allow max of 15 records in the history
      if (state.records.length >= 15) {
        state.records.shift();
      }

      // If image is not provided, set a default image
      if (!action.payload.image) {
        action.payload.image = Laptop;
      }

      if (!Array.isArray(state.records)) {
        state.records = []; // Ensure that records is always an array
      }

      // If timestamp is not provided, set it to the current time
      if (!action.payload.timestamp) {
        action.payload.timestamp = new Date().toISOString();
      }

      state.records.push(action.payload);
    },

    // Clear the history
    clearHistory: (state) => {
      state.records = [];
    },
  },
});

export const { addRecordToHistory, clearHistory } = historySlice.actions;
export default historySlice.reducer;
