/**
 * DESCRIPTION: This is a utility file that contains global constants used throughout the application.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// -- Define the Modal Types
export const MODAL_BODY_TYPES = {
  USER_DETAIL: "USER_DETAIL",
  USER_ADD_NEW: "USER_ADD_NEW",
  USER_EDIT: "USER_EDIT",
  USER_DELETE: "USER_DELETE",
  JSON_VIEWER: "JSON_VIEWER",
  LARGE_TYPE: "LARGE_TYPE",
  CONFIRMATION: "CONFIRMATION",
  DEVICE_OVERRIDES: "DEVICE_OVERRIDES",
  SEQUENCE_GROUPS: "SEQUENCE_GROUPS",
  DEFAULT: "",
};

// -- Define the Right Drawer Types
export const RIGHT_DRAWER_TYPES = {
  HISTORY: "HISTORY",
  NOTIFICATION: "NOTIFICATION",
};

// -- Define the Application Roles
export const APPLICATION_ROLES = {
  Engineer: "engineer", // Support Engineer (Can access production devices, logs and support controls)
  Tester: "tester", // Tester (Can access the test environment and manage test devices)
  Reader: "reader", // Reader (Read-only access to all environments and configurations)
  Administrator: "admin", // Administrator (Administrative access over all environments and configurations)
};

// -- Define the Device Stages
export const deviceUpgradeSteps = ["Awaiting Deployment", "Preparing For Provisioning", "Ready For Provisioning", "Preparing For Upgrade", "Ready For Upgrade", "Upgrading", "Finalising", "Complete"];
export const deviceSwapSteps = ["Awaiting Deployment", "Preparing For Provisioning", "Ready For Provisioning", "Preparing For Swap", "Ready For Swap", "Finalising", "Complete"];
export const allDeviceSteps = Array.from(new Set([...deviceUpgradeSteps, ...deviceSwapSteps]));
