/**
 * DESCRIPTION: This is a slice that contains the statistics state and actions to fetch the statistics.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import api from "../services/api.service";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showToastNotification } from "../utils/toastNotification";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define types and interfaces

interface StatisticsState {
  data: Awaited<ReturnType<typeof api.admin.getApiStatistics>>["data"] | null; // The statistics response
  status: "idle" | "loading" | "succeeded" | "failed"; // Track the status of the API call
  error: string | null;
}
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the initial state

const initialState: StatisticsState = {
  data: null,
  status: "idle",
  error: null,
};
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the async thunk to fetch the statistics

export const fetchApiStatistics = createAsyncThunk(
  "statistics/fetchApiStatistics",
  async ({ startDate, endDate, functionName, httpCode, httpRequests = true }: { startDate: string; endDate: string; functionName?: string; httpCode?: string; httpRequests?: boolean }) => {
    try {
      const params: Parameters<typeof api.admin.getApiStatistics>[0] = {};

      params.startDate = startDate;
      params.endDate = endDate;

      if (functionName) {
        params.functionName = functionName;
      }

      if (httpCode) {
        params.httpCode = httpCode;
      }

      params.httpRequests = httpRequests;

      const response = await api.admin.getApiStatistics(params);

      // If response is empty, show a notification
      if (response.data.TotalCallsPerFunction.length === 0) {
        showToastNotification("No API statistics found for the selected period. Try changing the date range.", "warning");
      }

      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch api statistics");
    }
  }
);
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the slice

const apiStatisticsSlice = createSlice({
  name: "apiStatistics",
  initialState,
  reducers: {
    // -- Reducer to manually update the statistics if needed
    setApiStatistacs(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApiStatistics.pending, (state) => {
        state.data = null;
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchApiStatistics.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchApiStatistics.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch statistics";
      });
  },
});
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Export the reducer and actions

export const { setApiStatistacs } = apiStatisticsSlice.actions;
export default apiStatisticsSlice.reducer;
