/**
 * DESCRIPTION: This is the sidebar routes file for the react app. This file contains the routes that are used to render the app's sidebar. Each route object contains the path and icon to render, as well as the name of the route.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import { APPLICATION_ROLES } from "../utils/globalConstantUtil";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Icons (Ensure they are imported separately to avoid tree shaking)

import Squares2X2Icon from "@heroicons/react/24/outline/Squares2X2Icon";
import Cog6ToothIcon from "@heroicons/react/24/outline/Cog6ToothIcon";
import ChartBarIcon from "@heroicons/react/24/outline/ChartBarIcon";
import { FaLaptop } from "react-icons/fa6";
import UsersIcon from "@heroicons/react/24/outline/UsersIcon";
import { AzCostManagement } from "../assets/icons";
import BoltIcon from "@heroicons/react/24/outline/BoltIcon";
import { MdDisplaySettings } from "react-icons/md";
import { DiRedis } from "react-icons/di";
import { IoEyeSharp } from "react-icons/io5";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// -- Define the icon classes

const iconClasses = `h-6 w-6`;
const submenuIconClasses = `h-5 w-5`;
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// -- Define the routes

const routes = [
  {
    path: "dashboard",
    icon: <Squares2X2Icon className={iconClasses} />,
    name: "Dashboard",
  },
  {
    path: "devices",
    icon: <FaLaptop className={iconClasses} />,
    name: "Devices",
  },
  {
    path: "analytics",
    icon: <ChartBarIcon className={iconClasses} />,
    name: "Analytics",
  },
  {
    path: "admin/api-statistics",
    icon: <BoltIcon className={iconClasses} />,
    name: "API Statistics",
    allowedRoles: [APPLICATION_ROLES.Administrator],
  },
  {
    path: "admin/redis-statistics",
    icon: <DiRedis className={iconClasses} />,
    name: "Redis Statistics",
    allowedRoles: [APPLICATION_ROLES.Administrator],
  },
  {
    path: "admin/audit-logs",
    icon: <IoEyeSharp className={iconClasses} />,
    name: "Audit Log",
    allowedRoles: [APPLICATION_ROLES.Administrator],
  },
  {
    path: "",
    icon: <Cog6ToothIcon className={`${iconClasses} inline`} />,
    name: "Settings",
    allowedRoles: [APPLICATION_ROLES.Administrator],
    submenu: [
      {
        path: "admin/settings/migration-agent",
        icon: <MdDisplaySettings className={submenuIconClasses} />,
        name: "Migration Agent",
      },
      {
        path: "admin/settings/user-management",
        icon: <UsersIcon className={submenuIconClasses} />,
        name: "User Management",
      },
      {
        path: "admin/settings/resource-costs",
        icon: <AzCostManagement className={submenuIconClasses} />,
        name: "Resource Costs",
      },
    ],
  },
];

export default routes;
