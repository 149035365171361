/**
 * DESCRIPTION: This is the modal body component that displays a confirmation message and two buttons to confirm or cancel the action.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the modal body component

interface ConfirmationModalProps {
  closeModal: () => void;
  extraObject: {
    body: JSX.Element;
    confirmText: string;
    confirmButtonClassList: string;
    confirmAction: () => Promise<void>;
  };
}

export default function ConfirmationModal({ closeModal, extraObject }: ConfirmationModalProps) {
  const handleConfirmAction = async () => {
    try {
      await extraObject?.confirmAction();
      closeModal(); // -- Close modal after action is complete
    } catch {
      // Suppress error
    }
  };

  return (
    <>
      <div className="modal-body">{extraObject.body}</div>
      <div className="modal-action mt-12">
        <button onClick={closeModal} className="btn btn-ghost">
          Cancel
        </button>
        <button onClick={handleConfirmAction} className={`btn btn-primary ${extraObject?.confirmButtonClassList ? extraObject.confirmButtonClassList : ""}`}>
          {extraObject?.confirmText ? extraObject.confirmText : "Confirm"}
        </button>
      </div>
    </>
  );
}
