/**
 * DESCRIPTION: This is a slice that contains the devices state and actions to fetch the devices.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import api from "../services/api.service";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showToastNotification } from "../utils/toastNotification";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define types and interfaces

interface DevicesState {
  data: Awaited<ReturnType<typeof api.devices.list>>["data"] | null;
  status: "idle" | "loading" | "succeeded" | "failed"; // Track the status of the API call
  error: string | null;
}
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the initial state

const initialState: DevicesState = {
  data: null,
  status: "idle",
  error: null,
};
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the async thunk

// -- Fetch the device records based on the provided parameters
export const fetchDevices = createAsyncThunk("devices/fetchDevices", async ({ search, stage, limit, offset }: { search?: string; stage?: string; limit: number; offset: number }) => {
  try {
    const params: Parameters<typeof api.devices.list>[0] = {
      limit,
      offset,
    };

    // -- Include search if present
    if (search) {
      params.search = search;
    }

    // -- Add filters
    if (stage) {
      params.stage = stage;
    }

    const response = await api.devices.list(params);

    // If devices are empty, show a notification
    if (response.data.totalDevices === 0) {
      showToastNotification("No devices found. Try changing the filters.", "warning");
    }

    return response?.data;
  } catch (error) {
    throw new Error("Failed to fetch devices!");
  }
});
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the slice

const devicesSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    // -- Reducer to manually update the records if needed
    setDevices(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDevices.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchDevices.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchDevices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch engine execution records";
      });
  },
});
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Export the reducer and actions

export const { setDevices } = devicesSlice.actions;
export default devicesSlice.reducer;
