/**
 * DESCRIPTION: This is a header component that displays the header of the application. The header contains the page title, theme switcher, notification bell, and user avatar.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import { themeChange } from "theme-change";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import OnlineUsers from "./components/OnlineUsers";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Redux Objects

import { RootState } from "../redux";
import { openRightDrawer } from "../redux/rightDrawer.slice";
import { APPLICATION_ROLES, RIGHT_DRAWER_TYPES } from "../utils/globalConstantUtil";
import { clearHistory } from "../redux/history.slice";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Icons

import BellIcon from "@heroicons/react/24/outline/BellIcon";
import Bars3Icon from "@heroicons/react/24/outline/Bars3Icon";
import MoonIcon from "@heroicons/react/24/outline/MoonIcon";
import SunIcon from "@heroicons/react/24/outline/SunIcon";
import { FaHistory } from "react-icons/fa";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the component

function Header() {
  const dispatch = useDispatch();

  // -- Get the page title and notification count from Redux
  const { noOfNotifications, pageTitle } = useSelector((state: RootState) => state.header);

  // -- Get the user photo from Redux
  const userPhoto = useSelector((state: RootState) => state.user.photo);

  // -- Get the user roles from Redux
  const user = useSelector((state: RootState) => state.user);
  const userRoles = user?.principal?.userRoles?.filter((role) => role !== "authenticated" && role !== "anonymous");

  // -- Functions to change the theme
  const [currentTheme, setCurrentTheme] = useState(localStorage.getItem("theme"));
  useEffect(() => {
    themeChange(false);
    if (currentTheme === null) {
      if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
        setCurrentTheme("dark");
      } else {
        setCurrentTheme("lofi");
      }
    }
  }, [currentTheme]);

  // -- Function to open right side drawer
  const openNotification = () => {
    dispatch(openRightDrawer({ header: "Notifications", bodyType: RIGHT_DRAWER_TYPES.NOTIFICATION }));
  };
  const openHistory = () => {
    const ClearHistoryButton = () => {
      return (
        <button className="btn btn-sm" onClick={() => dispatch(clearHistory())}>
          Clear History
        </button>
      );
    };

    dispatch(openRightDrawer({ header: "History", bodyType: RIGHT_DRAWER_TYPES.HISTORY, secondaryHeaderButton: <ClearHistoryButton /> }));
  };

  // -- Function to logout the user
  function logoutUser() {
    localStorage.clear();
    window.location.href = "/logout"; // Redirect to the Azure Static Web Apps logout page. View staticwebapp.config.json for the logout configuration.
  }

  return (
    <>
      <div className="navbar sticky top-0 bg-base-100 z-40 shadow-md">
        <div className="flex-1">
          <label htmlFor="left-sidebar-drawer" className="btn btn-ghost drawer-button lg:hidden">
            <Bars3Icon className="h-5 inline-block w-5" />
          </label>
          <h1 className="text-2xl font-semibold ml-2">{pageTitle}</h1>
        </div>

        {/* Show Online Users to Admins */}
        {userRoles?.includes(APPLICATION_ROLES.Administrator) && (
          <div className="flex-none">
            <OnlineUsers />
            <div className="divider divider-horizontal"></div>
          </div>
        )}

        {/* Theme switcher */}
        <div className="flex-none">
          <label className="swap swap-rotate">
            <input type="checkbox" />
            <SunIcon data-set-theme="lofi" data-act-class="ACTIVECLASS" className={"fill-current w-6 h-6 " + (currentTheme === "dark" ? "swap-on" : "swap-off")} />
            <MoonIcon data-set-theme="dark" data-act-class="ACTIVECLASS" className={"fill-current w-6 h-6 " + (currentTheme === "lofi" ? "swap-on" : "swap-off")} />
          </label>

          {/* Notification bell/button. Currently disabled as is not yet implemented, but keeping for future use */}
          {false && (
            <button className="btn btn-ghost ml-4 btn-circle" onClick={() => openNotification()}>
              <div className="indicator">
                <BellIcon className="h-6 w-6" />
                {noOfNotifications > 0 ? <span className="indicator-item badge badge-secondary badge-sm">{noOfNotifications}</span> : null}
              </div>
            </button>
          )}

          {/* History button */}
          <button className="btn btn-ghost ml-4 btn-circle" onClick={() => openHistory()}>
            <div className="indicator">
              <FaHistory className="h-6 w-6" />
            </div>
          </button>

          {/* Logged On User Avatar */}
          <div className="dropdown dropdown-end ml-4">
            <label tabIndex={0} className="btn btn-ghost btn-circle avatar online">
              <div className="w-10 rounded-full">
                {/* Use the user photo from Redux or default to '/user.png' */}
                <img src={userPhoto ? userPhoto : "/user.png"} alt="profile" />
              </div>
            </label>

            {/* User menu */}
            <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
              <span className="menu-title">{`${user?.data?.givenName} ${user?.data?.surname}`}</span>
              {userRoles?.map((role, index) => (
                <li key={index} className="justify-between">
                  <div>
                    App Role:
                    <span className="badge badge-secondary">{role.charAt(0).toUpperCase() + role.slice(1)}</span>
                  </div>
                </li>
              ))}
              <div className="divider mt-0 mb-0"></div>
              <li>
                <span onClick={logoutUser}>Logout</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
