import React, { useEffect, useState } from "react";
import api from "../../services/api.service";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";

interface UserAvatarProps {
  userId: string;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ userId }) => {
  const [photoUrl, setPhotoUrl] = useState<string | null>(null);
  const [fullName, setFullName] = useState<string>("");

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        // Fetch user photo
        const response = await api.getUserPhoto(userId);

        // If the photo exists, create an object URL
        if (response.data.size > 0) {
          const photoBlob = response.data;
          const url = URL.createObjectURL(photoBlob);
          setPhotoUrl(url);
        }

        // Use the response to get user details
        const { givenName, surname } = (await api.getUser(userId)).data;
        setFullName(`${givenName} ${surname}`);
      } catch (error) {
        console.error(`Error fetching data for user ${userId}:`, error);
        setFullName("Unknown User");
      }
    };

    fetchUserDetails();
  }, [userId]);

  return (
    <div className="tooltip tooltip-bottom" data-tip={`${fullName} is online`}>
      {photoUrl ? (
        <div className="avatar w-12 rounded-full">
          <img src={photoUrl} alt={`User ${userId}`} />
        </div>
      ) : (
        <div className="avatar placeholder">
          <div className="bg-neutral text-neutral-content w-10 rounded-full">
            {/* Initials fallback */}
            <span>
              {fullName
                ?.split(" ")
                ?.map((n) => n[0])
                ?.join("")}{" "}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

const OnlineUsers: React.FC = () => {
  const [userIds, setUserIds] = useState<string[]>([]);
  const maxVisibleAvatars = 10;
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    // Fetch the list of online users every 30 seconds
    const interval = setInterval(() => {
      api.admin.portalUsers.listOnline().then((response) => setUserIds(response.data.filter((id) => id !== user?.principal?.userId)));
    }, 30000);

    // Fetch the initial list of online users (Excluding the current user)
    api.admin.portalUsers.listOnline().then((response) => setUserIds(response.data.filter((id) => id !== user?.principal?.userId)));

    return () => clearInterval(interval);
  }, [user?.principal?.userId]);

  const visibleUsers = userIds.slice(0, maxVisibleAvatars);
  const remainingCount = userIds.length - maxVisibleAvatars;

  return (
    <div className="avatar-group -space-x-5 rtl:space-x-reverse !overflow-visible">
      {visibleUsers.map((userId) => (
        <UserAvatar key={userId} userId={userId} />
      ))}
      {remainingCount > 0 && (
        <div className="tooltip tooltip-bottom" data-tip={`${remainingCount} more users online`}>
          <div className="avatar placeholder">
            <div className="bg-neutral text-neutral-content w-10 rounded-full">
              <span>+{remainingCount}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnlineUsers;
