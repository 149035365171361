import { DeviceOverride } from "../../../../../api/src/types";
import api from "../../../services/api.service";

// Utility function to update device overrides
async function updateDeviceOverrides(device: { DeviceSerialNumber: string; Overrides?: DeviceOverride | null }, changes: Partial<DeviceOverride>) {
  // Create the updatedOverrides object by merging current overrides with changes
  const updatedOverrides: DeviceOverride = {
    id: device.DeviceSerialNumber,
    ...(device.Overrides ?? {}), // Spread existing overrides if they exist
    ...changes, // Apply changes
  };

  // Send updated overrides to the API
  await api.devices.overrides.post(device.DeviceSerialNumber, updatedOverrides);
}

export { updateDeviceOverrides };
