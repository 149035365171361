/**
 * DESCRIPTION: This is a modal layout component that is used to display different types of modals to the user.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import React from "react";
import { useSelector, useDispatch } from "react-redux";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Redux Objects

import { closeModal } from "../redux/modal.slice";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Componenets

import { MODAL_BODY_TYPES } from "../utils/globalConstantUtil";
import ConfirmationModal from "../components/Modals/ConfirmationModalBody";
import LargeTypeModal from "../components/Modals/LargeTypeModalBody";
import JsonViewerModal from "../components/Modals/JsonViewerModalBody";
import AddNewUserModal from "../features/settings/portalUsers/components/AddNewUserModalBody";
import EditUserModal from "../features/settings/portalUsers/components/EditUserModalBody";
import DeleteUserModal from "../features/settings/portalUsers/components/DeleteUserModalBody";
import DeviceOverridesModalBody from "../features/device/components/DeviceOverridesModalBody";
import SequenceGroupsModalBody from "../features/device/components/SequenceGroupsModalBody";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the component

// -- Define the modal layout components
const ConfirmationModalAny = ConfirmationModal as React.ComponentType<any>;
const LargeTypeModalAny = LargeTypeModal as React.ComponentType<any>;
const JsonViewerModalAny = JsonViewerModal as React.ComponentType<any>;
const AddNewUserModalAny = AddNewUserModal as React.ComponentType<any>;
const EditUserModalAny = EditUserModal as React.ComponentType<any>;
const DeleteUserModalAny = DeleteUserModal as React.ComponentType<any>;
const DeviceOverridesModalBodyAny = DeviceOverridesModalBody as React.ComponentType<any>;
const SequenceGroupsModalBodyAny = SequenceGroupsModalBody as React.ComponentType<any>;

function ModalLayout() {
  const { isOpen, bodyType, size, extraObject, title } = useSelector((state: any) => state.modal);
  const dispatch = useDispatch();

  // -- Function to close the modal
  const close = () => {
    dispatch(closeModal());
  };

  return (
    <>
      <div className={`modal ${isOpen ? "modal-open" : ""}`}>
        <div className={`modal-box ${size === "lg" ? "max-w-7xl" : ""} flex flex-col relative`}>
          {/* Close Button */}
          <button className="btn btn-sm btn-circle absolute right-6 top-6" onClick={close}>
            ✕
          </button>
          <h3 className="font-semibold text-2xl pb-6 text-center">{title}</h3>

          {/* Modal Body */}
          <div className="flex-1 overflow-y-auto mt-4">
            {
              {
                [MODAL_BODY_TYPES.CONFIRMATION]: <ConfirmationModalAny extraObject={extraObject} closeModal={close} />,
                [MODAL_BODY_TYPES.LARGE_TYPE]: <LargeTypeModalAny extraObject={extraObject} closeModal={close} />,
                [MODAL_BODY_TYPES.JSON_VIEWER]: <JsonViewerModalAny extraObject={extraObject} closeModal={close} />,
                [MODAL_BODY_TYPES.USER_ADD_NEW]: <AddNewUserModalAny extraObject={extraObject} closeModal={close} />,
                [MODAL_BODY_TYPES.USER_EDIT]: <EditUserModalAny extraObject={extraObject} closeModal={close} />,
                [MODAL_BODY_TYPES.USER_DELETE]: <DeleteUserModalAny extraObject={extraObject} closeModal={close} />,
                [MODAL_BODY_TYPES.DEVICE_OVERRIDES]: <DeviceOverridesModalBodyAny extraObject={extraObject} closeModal={close} />,
                [MODAL_BODY_TYPES.SEQUENCE_GROUPS]: <SequenceGroupsModalBodyAny extraObject={extraObject} closeModal={close} />,
                [MODAL_BODY_TYPES.DEFAULT]: <div></div>,
              }[bodyType]
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalLayout;
