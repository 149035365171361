/**
 * DESCRIPTION: This is a slice that contains the operations records state and actions to fetch the records.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import api from "../services/api.service";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showToastNotification } from "../utils/toastNotification";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define types and interfaces

interface AnalyticsState {
  data: Awaited<ReturnType<typeof api.getAnalytics>>["data"] | null; // The analytics response
  status: "idle" | "loading" | "succeeded" | "failed"; // Track the status of the API call
  error: string | null;
}
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the initial state

const initialState: AnalyticsState = {
  data: null,
  status: "idle",
  error: null,
};
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the async thunk

// -- Fetch the operations records based on the provided parameters
export const fetchAnalytics = createAsyncThunk("analytics/fetchAnalytics", async () => {
  try {
    const response = await api.getAnalytics();

    // If analytics are empty, show a notification
    if (response.data.devicesByManufacturer.length === 0) {
      showToastNotification("No analytics found for the selected period. Try changing the date range.", "warning");
    }

    return response?.data;
  } catch (error) {
    throw new Error("Failed to fetch analytics");
  }
});
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the slice

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    // -- Reducer to manually update the records if needed
    setAnalytics(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnalytics.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAnalytics.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchAnalytics.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch analytics";
      });
  },
});
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Export the reducer and actions

export const { setAnalytics } = analyticsSlice.actions;
export default analyticsSlice.reducer;
