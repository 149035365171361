import { toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS

// Initialize toast configuration
export const showToastNotification = (message: string = "", status?: "info" | "success" | "warning" | "error" | null, autoClose?: number) => {
  const options: ToastOptions = {
    autoClose: autoClose ? autoClose : 5000,
    hideProgressBar: false, // Display the progress bar
    closeOnClick: true, // Close the toast when clicked
    draggable: true, // Allow the toast to be dragged
    pauseOnHover: true, // Pause the timer when hovering over the toast
    position: "bottom-right", // Position the toast at the bottom-center
    theme: "dark",
  };

  switch (status) {
    case "info":
      toast.info(message, options);
      break;
    case "success":
      toast.success(message, options);
      break;
    case "warning":
      toast.warn(message, options);
      break;
    case "error":
      toast.error(message, options);
      break;
    default:
      toast(message, options); // Default toast for general messages
      break;
  }
};
