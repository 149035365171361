/**
 * DESCRIPTION: This is the NotificationBodyRightDrawer component that is used to display the notifications in the right drawer.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Redux Objects

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { HistoryRecord } from "../../redux/history.slice";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the component

const HistoryItem = ({ item }: { item: HistoryRecord }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(item.url);
  };

  return (
    <div className={`flex items-center space-x-3 py-2 transition-transform duration-300 transform rounded-xl hover:bg-base-200 cursor-pointer p-2`} onClick={() => handleClick()}>
      <div className="avatar">
        <div className="w-12">
          <img src={item.image} alt={item.serial} className="object-contain" />
        </div>
      </div>
      <div>
        <div className="font-bold">{item.serial}</div>
        <div className="text-sm text-gray-500 font-medium">{item.upn}</div>
      </div>
    </div>
  );
};

function HistoryBodyRightDrawer() {
  const history = useSelector((state: RootState) => state.history);

  return (
    <>
      {history?.records
        ?.slice() // Create a shallow copy of the array
        ?.sort((a, b) => b.timestamp.localeCompare(a.timestamp))
        ?.filter(
          (item, index, self) => index === self.findIndex((t) => t.id === item.id) // Remove duplicates based on `id`
        )
        ?.map((item, i) => (
          <HistoryItem key={i} item={item} /> // Use the component for each history item
        ))}
    </>
  );
}

export default HistoryBodyRightDrawer;
