import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import EnvironmentRoutes from "../routes/environment.routes";
type EnvironmentRoutesType = (typeof EnvironmentRoutes)[number];

interface EnvironmentState {
  state: EnvironmentRoutesType;
}

const initialState: EnvironmentState = {
  state: null,
};

const environmentSlice = createSlice({
  name: "environment",
  initialState,
  reducers: {
    setEnvironment: (state, action: PayloadAction<EnvironmentRoutesType>) => {
      state.state = action.payload;
    },
  },
});

export const { setEnvironment } = environmentSlice.actions;

export default environmentSlice.reducer;
