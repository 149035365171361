/**
 * DESCRIPTION: This is a slice that contains the date picker state and actions to update the date range.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Redux Objects

import { AppDispatch } from ".";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the state and initial state

interface DateRange {
  startDate: string | null; // Store dates as strings (ISO format)
  endDate: string | null;
}

interface DatePickerState {
  dateRange: DateRange;
}

const initialState: DatePickerState = {
  dateRange: {
    startDate: new Date().toISOString().split("T")[0], // Initialize with current date in ISO format
    endDate: new Date().toISOString().split("T")[0],
  },
};
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the slice

const datePickerSlice = createSlice({
  name: "datePicker",
  initialState,
  reducers: {
    // -- Action to update the date range in the state
    setDateRange(state, action: PayloadAction<DateRange>) {
      state.dateRange = action.payload;
    },
  },
});
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the actions

export const updateDateRange = (newRange: { startDate: Date | null; endDate: Date | null }) => (dispatch: AppDispatch) => {
  // -- Helper function to adjust the date for timezone offset and keep it as an ISO string
  const formatDateToISOWithTimezone = (date: Date | null) => {
    if (!date) return null;
    // -- Adjust for timezone offset to keep local date correct
    const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return adjustedDate.toISOString().split("T")[0]; // Return date in ISO string (yyyy-mm-dd)
  };

  // -- If end date is in the future, set it to today
  if (newRange.endDate && new Date(newRange.endDate) > new Date()) {
    newRange.endDate = new Date();
  }

  const formattedRange = {
    startDate: formatDateToISOWithTimezone(newRange.startDate),
    endDate: formatDateToISOWithTimezone(newRange.endDate),
  };

  // -- Dispatch the updateDateRange action to update the date range
  dispatch(setDateRange(formattedRange));
};
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Export the action and reducer

export const { setDateRange } = datePickerSlice.actions;
export default datePickerSlice.reducer;
