/**
 * DESCRIPTION: This is the modal body component that displays a string of text in large type for easy reading.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define types and interfaces

interface LargeTypeModalProps {
  closeModal: () => void;
  extraObject: { text: string };
}
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the component

export default function LargeTypeModalBody({ extraObject }: LargeTypeModalProps) {
  const characters = extraObject.text.split("");

  // Helper function to chunk array into groups of 14
  const chunkArray = (arr: string[], size: number) => {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  };

  const characterChunks = chunkArray(characters, 14); // Break the characters into groups of 14

  return (
    <div className="flex flex-col justify-center items-center p-4 w-full">
      {characterChunks.map((chunk, chunkIndex) => (
        <div key={chunkIndex} className="flex justify-center items-center space-x-2 mb-4">
          {chunk.map((char, index) => (
            <div key={index} className={`flex flex-col items-center justify-center w-16 h-24 ${index % 2 === 0 ? "bg-slate-600" : "bg-gray-900"} rounded-lg shadow-md`}>
              <span className="text-5xl font-bold text-white">{char}</span>
              <span className="text-blue-400 mt-2 text-lg">{chunkIndex * 14 + index + 1}</span> {/* Adjusted for the line */}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
