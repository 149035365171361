/**
 * DESCRIPTION: This is the modal body component that is used to delete a user from the application.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import { useState } from "react";
import api from "../../../../services/api.service";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define types and interfaces

import { User } from "../../../../types/user";
interface DeleteUserModalBodyProps {
  closeModal: () => void;
  extraObject: {
    user: User;
    refreshAction: () => void;
  };
}
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the component

export default function DeleteUserModalBody({ closeModal, extraObject }: DeleteUserModalBodyProps) {
  const [loading, setLoading] = useState(false); // For loading spinner
  const user = extraObject.user;

  // -- Handle the delete user action
  const handleDelete = async () => {
    setLoading(true);

    try {
      // -- Delete the user
      await api.admin.portalUsers.delete(user.id);
      closeModal();
      extraObject.refreshAction();
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("Failed to delete user.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* User Info */}
      <div className="flex items-center space-x-3 justify-center">
        <img src={user.avatar ? user.avatar : "/user"} alt={user.name} className="w-16 h-16 rounded-full" />
        <div>
          <div className="font-bold">{user.name}</div>
          <div className="text-sm text-gray-500">{user.email}</div>
        </div>
      </div>

      {/* Confirmation Message */}
      <div className="mt-4 text-center">
        <p>Are you sure you wish to delete {user.name}?</p>
      </div>

      {/* Action Buttons */}
      <div className="modal-action mt-10 justify-center">
        <button className="btn btn-ghost" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-danger" onClick={handleDelete} disabled={loading}>
          {loading ? "Deleting..." : "Delete User"}
        </button>
      </div>
    </>
  );
}
