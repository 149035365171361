/**
 * DESCRIPTION: This is a slice that contains the modal state and actions to open and close the modal.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the state and initial state

interface ModalState {
  title: string;
  isOpen: boolean;
  bodyType: string;
  size: string;
  extraObject: any;
}

const initialState: ModalState = {
  title: "",
  isOpen: false,
  bodyType: "",
  size: "",
  extraObject: {},
};
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the slice

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{
        title: string;
        bodyType: string;
        extraObject?: any;
        size?: string;
      }>
    ) => {
      const { title, bodyType, extraObject, size } = action.payload;
      state.isOpen = true;
      state.bodyType = bodyType;
      state.title = title;
      state.size = size || "md";
      state.extraObject = extraObject;
    },

    // Remove 'action' parameter since it's not used
    closeModal: (state) => {
      state.isOpen = false;
      state.bodyType = "";
      state.title = "";
      state.extraObject = {};
    },
  },
});
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Export the action and reducer

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
