/**
 * DESCRIPTION: This is a custom utility function that searches for users and fetches their photos from the API.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import { User } from "@microsoft/microsoft-graph-types";
import api from "../services/api.service";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the type for user search results with photos

// Define the type for user search results
interface UserSearchResult {
  id: string;
  displayName: string;
  userPrincipalName: string;
  photo: string;
}
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the function

async function fetchUserPhoto(userId: string): Promise<string> {
  try {
    const response = await api.getUserPhoto(userId);
    if (response.data.size > 0) {
      const photoBlob = response.data;
      return URL.createObjectURL(photoBlob);
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export async function searchUsersWithPhotos(query: string): Promise<UserSearchResult[]> {
  if (query.length < 3) {
    return []; // If the query is too short, return an empty array
  }

  try {
    // Fetch users from your API
    const response = await api.searchUsers(query);
    const users: User[] = response.data;

    // Transform User objects into UserSearchResult and fetch their photos
    const usersWithPhotos = await Promise.all(
      users.map(async (user: User): Promise<UserSearchResult> => {
        const displayName = `${user.givenName ?? "Unknown"} ${user.surname ?? ""}`.trim(); // Combine given name and surname
        const userSearchResult: UserSearchResult = {
          id: user.id ?? "", // Handle potential undefined values
          displayName: displayName,
          userPrincipalName: user.userPrincipalName ?? "Unknown",
          photo: "", // Will be replaced by the fetched photo
        };

        // Fetch the user's photo
        const photo = await fetchUserPhoto(user.id ?? "");
        return { ...userSearchResult, photo }; // Add the photo to the user search result
      })
    );

    return usersWithPhotos; // Return the users with their photos
  } catch (error) {
    console.error("Error searching users with photos:", error);
    throw new Error("Failed to search users with photos");
  }
}
