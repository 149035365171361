/**
 * DESCRIPTION: This is a slice that contains the statistics state and actions to fetch the statistics.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import api from "../services/api.service";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showToastNotification } from "../utils/toastNotification";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define types and interfaces

interface StatisticsState {
  data: Awaited<ReturnType<typeof api.getStatistics>>["data"] | null; // The statistics response
  status: "idle" | "loading" | "succeeded" | "failed"; // Track the status of the API call
  error: string | null;
}
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the initial state

const initialState: StatisticsState = {
  data: null,
  status: "idle",
  error: null,
};
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the async thunk to fetch the statistics

export const fetchStatistics = createAsyncThunk("statistics/fetchStatistics", async () => {
  try {
    const response = await api.getStatistics();

    // If response is empty, show a notification
    if (response.data.TotalDevices === 0) {
      showToastNotification("No devices were found.", "warning");
    }

    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch statistics");
  }
});
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the slice

const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    // -- Reducer to manually update the statistics if needed
    setStatistics(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatistics.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchStatistics.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchStatistics.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch statistics";
      });
  },
});
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Export the reducer and actions

export const { setStatistics } = statisticsSlice.actions;
export default statisticsSlice.reducer;
